
	<mat-card *ngIf="status == 'ok'">
        <div class="ico-ok">
            <ion-icon slot="icon-only" mode="md" name="checkmark-circle"></ion-icon>
        </div>
        <div class="ion-padding">
            <h1>{{'Congratulations!'|translate}}</h1>
            <p>{{'You have modified configuration fields correctly'|translate}}</p>
        </div>
        <div class="ion-padding">
            <p>{{'Now you can go to dashboard page'|translate}}</p>
        </div>
        <div class="ion-padding">
            <button class="btn-scan" mat-flat-button color="primary" (click)="gotoDashBoard()">{{"Go to the dashboard page" | translate }}</button>
        </div>
    </mat-card>


	<mat-card *ngIf="status != 'ok'">
        <div class="ico-ko">
            <ion-icon name="close-circle"></ion-icon>
        </div>
        <div class="ion-padding">
            <h1>{{'Error on writing operation'|translate}}</h1>
            <p>{{'Some configurations could not be saved'|translate}}</p>
        </div>
        <div class="ion-padding">
            <p>{{'The following fields have given a bluetooth writing error:'|translate}}</p>
            <p *ngFor="let error of errors">
                {{error}}
            </p>
        </div>
        <div class="ion-padding">
            <button class="btn-scan" mat-flat-button color="primary" (click)="gotoForm()">{{"Go to Configuration form" |translate}}</button>
        </div>
    </mat-card>

