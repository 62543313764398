import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DashboardComponent } from './../dashboard/dashboard.component';

/**
 * Generated class for the ScanOkPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */


@Component({
  selector: 'operation-status.component.html',
  templateUrl: 'operation-status.component.html',
})
export class OperationStatusComponent implements OnInit {
    status: string = "";
    errors: string[] = [];
    deviceType: any;
  constructor(public router: Router,  public activatedRoute: ActivatedRoute) {

  }
    ngOnInit(): void {
        this.activatedRoute.params.subscribe(data => {
            console.log("params");
            console.log(data);
            console.log(data.functionalityId);
            this.status =data.status;
            this.errors =data.errors;
            this.deviceType =data.device;
        })
        console.log(this.status)
        console.log(this.errors)
        console.log(this.deviceType)
    }

  ionViewDidLoad() {
    console.log('ionViewDidLoad OperationStatusComponent');
  }



  gotoDashBoard(){
    this.router.navigate(['/dashboard']);
  }

  gotoForm(){
      console.log(this.deviceType);
    switch(this.deviceType){
        case "E3-MINI-WIFI":
            this.router.navigate(['/wifi']);
            break;     
        default:
            this.router.navigate(['/dashboard']);
            break;   
    }
  }

}
