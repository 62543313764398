import { Component, OnInit } from '@angular/core';

import { BleService } from '../../providers/ble.service';

import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as DeviceNames from '../../../assets/configs/device-names.json';
import { StoreService } from '../../providers/store.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public deviceSelected: any = null;
  public deviceName?: String = '';
  public isDeviceSelected: Boolean = false;
  selecteableDevices: any[] = [];
  searchForm: FormGroup;

  constructor(public bleService: BleService, private router: Router, private storeService:StoreService, private translateService:TranslateService) {

    this.selecteableDevices = storeService.devicesRegistered;
   /* this.translateService.stream("All devices").subscribe((data)=>{
      this.selecteableDevices = [];
      this.selecteableDevices.push(
        {
          model: '',
          modelLabel: data,
          devicesName: [''],
          searchFilterObjects:[""]
        }
      )
      for(let deviceData of storedselecteableDevices){
        console.log(deviceData);
        this.selecteableDevices.push(deviceData);
      }
    });  */ 
    console.log( this.selecteableDevices)
    this.searchForm = new FormGroup({
      deviceToFind: new FormControl('', []),
    });
  }

  ngOnInit(): void {}

  ionViewDidLeave() {}

  scanDevices(): void {
    this.bleService

      .scanDevices(
        this.searchForm.value.deviceToFind == '',
        this.searchForm.value.deviceToFind
      )
      .pipe(first())
      .subscribe((device) => {
        this.deviceSelected = true;
        this.deviceName = device.name;
        this.isDeviceSelected = true;
        this.bleService.selectedDevice = device;
        console.log("this.bleService.selectedDevice");
        console.log(this.bleService.selectedDevice);
      });
  }

  connectDevice(): void {
       this.router.navigate(['/wifi']);
  }
  compareFunction(o1: any, o2: any) {
    return o1.id === o2.id;
  }
}
