import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { OperationStatusComponent } from './pages/operation-status/operation-status.component';
import { WifiComponent } from './pages/wifi/wifi.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  { path: '', component: DashboardComponent },
  { path: 'wifi', component: WifiComponent },
  { path: 'status', component: OperationStatusComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule {}
