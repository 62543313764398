<mat-card>
  <div layout="row" layout-xs="column">
    <form class="form" [formGroup]="searchForm" novalidate>
      <mat-form-field class="full-width">
        <mat-label>{{"Device to find" | translate }}</mat-label>

        <mat-select formControlName="deviceToFind">
          <mat-option
            *ngFor="let device of selecteableDevices"
            [value]="device.model">
            {{ device.modelLabel }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="scan-row" flex>
        <button
          class="btn-scan"
          mat-flat-button
          color="primary"
          (click)="scanDevices()"
          [disabled]="searchForm.invalid">
          {{"Scan devices" | translate }}
        </button>
      </div>
    </form>

    <div class="scan-row" *ngIf="isDeviceSelected" flex>
      <p> {{"Device selected:" | translate }} {{ deviceName }}</p>
      <button
        class="btn-scan"
        mat-flat-button
        color="primary"
        (click)="connectDevice()">
      {{"Connect Device" | translate }}
      </button>
    </div>
  </div>
</mat-card>
