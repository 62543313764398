import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BLE_API, IDevice } from '../interfaces';
import { GlobalConstantsService } from '../utils/constants.service'

@Injectable({
  providedIn: 'root'
})
export class StoreService {




  devicesRegistered: IDevice[] = [];
  //Datos guardados en memoria
  STORAGE_LANGUAGE_SELECTED: String = "Language";

  constructor() {
    this.init();
  }

  init() {
    let self = this;
    this.devicesRegistered = [
      {
        model: 'E3-mini',
        modelLabel:'E3-Mini',
        devicesName: ['E3-mini-0053', 'E3-mini-0054'],
        api: BLE_API.WIFI,
        pinEnabled : true,
        pin : "010101",
        dirName: "Wi-Fi",
        searchFilterObjects:[{namePrefix:"E3-Mini"}]
      }
    ];
  }
  searchForRegisteredDeviceByModel(model: string):IDevice {
    let deviceConfigFound:IDevice = {};
    let found:boolean = false;
    this.devicesRegistered.forEach(deviceElement => {
      console.log("devModelName->"+deviceElement.model+", modelname="+model );
        if(deviceElement.model!=undefined && deviceElement.model.toLowerCase()=== model.toLowerCase() && !found) {
          found=true;
          deviceConfigFound=deviceElement;
      }
    });
    console.log('deviceConfigFound'+ JSON.stringify(deviceConfigFound));
    return deviceConfigFound;
  }

  //TODO CREAR NOVA FUNCIÓ QUE FUNCIONI PER PATTERN
  searchForRegisteredDeviceByNamePattern(devicename: string):IDevice {
    console.log("searchForRegisteredDeviceByNamePattern");
    let deviceConfigFound:IDevice = {};
    let found:boolean = false;
    let searchName = devicename.toUpperCase()
    console.log("searchName => "+searchName);
    this.devicesRegistered.forEach(deviceElement => {
     
      if (deviceElement.model != undefined) {
          console.log(deviceElement);
          console.log(searchName);
          console.log(found);
          if(deviceElement.model != undefined && searchName.toUpperCase().indexOf(deviceElement.model.toUpperCase())>-1  && !found) {
            found=true;
            deviceConfigFound=deviceElement;
            console.log("device found->"+JSON.stringify(deviceConfigFound));
          }
      }
    });
    console.log('deviceConfigFound'+ JSON.stringify(deviceConfigFound));
    return deviceConfigFound;
  }
  
  searchForRegisteredDeviceByDevice(devicename: string):IDevice {
    let deviceConfigFound:IDevice = {};
    let found:boolean = false;
    this.devicesRegistered.forEach(deviceElement => {
      if (deviceElement.devicesName != undefined) {
        for (let deviceNameAct of deviceElement.devicesName) {
          console.log("deviceNameAct->"+deviceNameAct+", devicename="+devicename );
          if(deviceNameAct!=undefined && deviceNameAct.toLowerCase()=== devicename.toLowerCase() && !found) {
            found=true;
            deviceConfigFound=deviceElement;
            console.log("device found->"+JSON.stringify(deviceConfigFound));
          }
        }  
      }
    });
    console.log('deviceConfigFound'+ JSON.stringify(deviceConfigFound));
    return deviceConfigFound;
  }
}
