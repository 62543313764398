<mat-toolbar color="primary">
  <a [routerLink]="'dashboard'">
    <img src="assets/img/bluetooth.jpg" class="bluetoothimg"
  /></a>
  <p>
    Angular Web BLE Demo
  </p>
  <div *ngIf="settedLanguage == 'es'" style="margin-left: 10%;font-weight: 800;">
    <span style="color: rgba(0,255,0,0.85);">ES</span>
    <span style="padding-left: 20%;" (click)="changeLanguage('en')">EN</span>
  </div>
  <div *ngIf="settedLanguage == 'en'" style="margin-left: 10%;font-weight: 800;">
    <span (click)="changeLanguage('es')">ES</span>
    <span style="padding-left: 20%; color: rgba(0,255,0,0.85);">EN</span>  
  </div>


</mat-toolbar>
<router-outlet></router-outlet>
