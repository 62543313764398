import { Injectable } from "@angular/core";

export enum Encoding {
    UTF8 = 'utf8',
    HEX = 'hex',
    BASE64 = 'base64',
    BINARY = 'binary',
    LATIN1 = 'latin1'
  }

@Injectable({
    providedIn: 'root'
  })
  export class UtilsService {
    constructor() { }

    stringToBytes(str:string):ArrayBuffer {
        var array = new Uint8Array(str.length);

        for(var i=0;i<str.length;i++) {
            let charCode = str.charCodeAt(i).toString();
            console.log("charCode => "+charCode);
            array[i] = str.charCodeAt(i);
        }
        console.log(array)
        
        return array.buffer;
     }
    
      convertToHex(str:string) {
        var hex = '';
        for(var i=0;i<str.length;i++) {
            hex += ''+str.charCodeAt(i).toString(16);
        }
        return hex;
      }
    
      /**
       * Converts an unsignet integer to buffer
       * @param number value to convert to bytes
       * @param type uInt32, uInt16, uInt, Int
       */
      numberToBytes(number: number | any, type: string = 'uInt16'):ArrayBuffer|Uint8Array|Uint16Array|Uint32Array|[] {
        let data: Uint8Array | Uint16Array | Uint32Array | [] = [];
    
        if (type === 'uInt32') data = new Uint32Array(1);
        if (type === 'uInt16') data = new Uint16Array(1);
        if (type === 'uInt') data = new Uint8Array(1);
        if (type === 'Int') {
          const bytes = new Int8Array(number);
          const dv = new DataView(bytes.buffer);
          return dv.buffer;
        }else{
          return data;
        }
      }
    
      ipToBytes(ip: string, type: string = 'uInt16'): ArrayBuffer{
        console.log("ipToBytes function");
        let buffer: ArrayBuffer;
        let bufferView: Uint16Array | Uint8Array;
        try {
          buffer = new ArrayBuffer(4); // 2 bytes for each char
          bufferView = new Uint16Array(buffer);
          return buffer;
        } catch (e) {
           buffer = new ArrayBuffer(ip.length);
           return buffer;
        }
    
      }
    
      // Used for temperature
      bytesToBoolean(buffer: ArrayBuffer): boolean {
        const data = new Uint8Array(buffer);
        return Boolean(data[0]);
      }
    
      /**
       * Converts an ArrayBuffer to a number
       */
      bytesToNumber(buffer: ArrayBuffer, type: string = 'uInt16', littleEndian: boolean = true) {
        const bytes = new Uint8Array(buffer);
        const dv = new DataView(bytes.buffer);
    
        if (type === 'uInt32') return dv.getUint32(0, littleEndian);
        if (type === 'uInt16') return dv.getUint16(0, littleEndian);
        if (type === 'uInt') return dv.getUint8(dv.byteOffset);
        if (type === 'Int16') return dv.getInt8(dv.byteOffset);
        return null;
      }
    
      /**
       * Converts an ArrayBuffer (bytes) to string
       */
       bytesToString(buffer: ArrayBuffer, encoding: Encoding = Encoding.UTF8): string {
        const uint8 = new Uint8Array(buffer);
    
        if (encoding === Encoding.HEX) {
          let out = '';
          for (let i = 0, l = uint8.byteLength; i < l; ++i) {
            out += this.toHex(uint8[i]);
          }
          return out;
        }

        let charValues : number[] = [];
        for (let i = 0, l = uint8.byteLength; i < l; ++i) {
          charValues.push(parseInt(this.toHex(uint8[i]),16));
        }
        console.log("charValues ====> ");
        console.log(charValues);
        const str = String.fromCharCode.apply(null, charValues);
    
        if (encoding === Encoding.BASE64) return btoa(str);
        if (encoding === Encoding.BINARY || encoding === Encoding.LATIN1) return str;
    
        try {
          return str;
        } catch (e) {
          console.log(e);
          return '';
          //return String.fromCharCode.apply(null, new Uint16Array(buffer));
        }
      }
    
      /**
       * Converts to hex
       */
      toHex(n: number): string {
        if (n < 16) { return '0' + n.toString(16); }
        console.log(n+' convertido a hexadecimal es ' + n.toString(16));
        return n.toString(16);
      }
    
      /** TEST
       * Converts a String to Hex and returns the result.
       */
      stringToHex(string: string) {
        try {
          return string.split('').map((v) => {
            return v.charCodeAt(0).toString(16);
          }).join('');
        } catch (e) {
          console.log('Invalid text input: ' + string);
          return null
        }
      }
    
      /** TEST
      * Converts a Hex to a 8 bit unsigned integer ArrayBuffer
      */
      hexToBytes(hex: string): ArrayBuffer {
        if (typeof hex !== 'string') {
          throw new TypeError('Expected input to be a string');
        }
    
        if ((hex.length % 2) !== 0) {
          throw new RangeError('Expected string to be an even number of characters');
        }
    
        const view = new Uint8Array(hex.length / 2);
    
        for (let i = 0; i < hex.length; i += 2) {
          view[i / 2] = parseInt(hex.substring(i, i + 2), 16);
        }
    
        return view.buffer;
      }
  }