import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { StoreService } from '../providers/store.service';
import { UtilsService } from '../utils/utils.service';
import { IDevice } from '../interfaces';

import {
  BluetoothCore,
  consoleLoggerServiceConfig,
} from '@manekinekko/angular-web-bluetooth';


@Injectable({
  providedIn: 'root',
})
export class BleService {

  private isDeviceSelected: Boolean = false;
  public selectedDevice: any;

  constructor(public ble: BluetoothCore, private storeService: StoreService, private utilsService: UtilsService) {
    this.isDeviceSelected = false;
  }

  scanDevices(
    acceptAllDevices: boolean,
    model: string
  ): Observable<BluetoothDevice> {
    console.log('Getting Devices to scan...');
    //let deviceFamily:IDevice = this.storeService.searchForRegisteredDeviceByModel(model);
    let deviceFamily:IDevice = this.storeService.searchForRegisteredDeviceByNamePattern(model);
    let searchFilterObjects;
    let optionalServices;
    if (deviceFamily != undefined) {
      searchFilterObjects = deviceFamily.searchFilterObjects;
      if (deviceFamily.api != undefined) {
        optionalServices = deviceFamily.api.service;
      }
    }
    return (
      this.ble
        // 1) call the discover method will trigger the discovery process (by the browser)
        .discover$({
          acceptAllDevices: acceptAllDevices,
          filters: !acceptAllDevices ? searchFilterObjects : undefined,
          optionalServices:  !optionalServices?[]:[optionalServices]
        })
        .pipe((object) => {
          return this.getDevice().pipe(
            map((device) => {
              console.log('inside device');
              return device;
            })
          );
        })
    );

    /*// 2) get that service
            mergeMap((gatt: BluetoothRemoteGATTServer) => {
              return this.ble.getPrimaryService$(gatt, BleService.GATT_PRIMARY_SERVICE);
            }),
  
            // 3) get a specific characteristic on that service
            mergeMap((primaryService: BluetoothRemoteGATTService) => {
              return this.ble.getCharacteristic$(primaryService, BleService.GATT_CHARACTERISTIC_BATTERY_LEVEL);
            }),
  
            // 4) ask for the value of that characteristic (will return a DataView)
            mergeMap((characteristic: BluetoothRemoteGATTCharacteristic) => {
              return this.ble.readValue$(characteristic);
            }),
  
            // 5) on that DataView, get the right value
           // map((value: DataView) => value.getUint8(0))*/
    //  } )
  }

  getDevice() {
    return this.ble.getDevice$();
  }

  connectDevice(selectedDevice: BluetoothDevice) {
    return this.ble.connectDevice(selectedDevice).then((ok) => {
      console.log('device connected');
    });
  }

  async readValue(selectedDevice: BluetoothDevice, serviceUUID: string, characteristicUUID: string, type?: string) {
   
        
    const gatt:BluetoothRemoteGATTServer = await this.ble.connectDevice(selectedDevice);

    const primaryService = await this.ble.getPrimaryService(gatt, serviceUUID) as BluetoothRemoteGATTService;

    const characteristic = await this.ble.getCharacteristic(primaryService, characteristicUUID) as BluetoothRemoteGATTCharacteristic;

    const value = await characteristic.readValue();
    switch (type) {
      case 'string':
         return this.utilsService.bytesToString(value.buffer);
        break;     
      case 'bool':
        let result = this.utilsService.bytesToBoolean(value.buffer) 
        return String(result);
        break;
      case 'IP':
          let ip = '';
          let currentIndex = 1;
          const IPBytes = new Uint8Array(value.buffer);
          console.log(IPBytes);
          for(let value of IPBytes){
            ip = ip + value
            if(currentIndex < IPBytes.length ){
              ip= ip+"."
            }
            currentIndex = currentIndex + 1
          }
          if(ip == "00"){
            ip="0.0.0.0"
          }
          return ip;                
      break;	
      case 'MAC':
        let mac = '';
        let currentIndex2 = 1;
        const IPBytes2 = new Uint8Array(value.buffer);
        for(let value of IPBytes2){
          mac = mac + this.utilsService.toHex(value)
          if(currentIndex2 < IPBytes2.length ){
            mac= mac+":"
          }
          currentIndex2 = currentIndex2 + 1
        }
        return mac.toUpperCase();                
  break;	       
      default:
        if(typeof type !== 'undefined'){
          return this.utilsService.bytesToNumber(value.buffer, type);
        }
        
        return this.utilsService.bytesToNumber(value.buffer, 'uInt');
        break;
    }
  }

  disconectAll() {}

  streamValues() {
    return this.ble
      .streamValues$()
      .pipe(map((value: DataView) => value.getInt8(0)));
  }



  
  async writeValue(selectedDevice: BluetoothDevice, serviceUUID: string, characteristicUUID: string, value: any, type?: string) {
      console.log(characteristicUUID);
      console.log("bleService.writeValue")
      let buffer: any;
      switch (type) {
        case 'string':
        case 'String':
          buffer = this.utilsService.stringToBytes(value);
          //buffer = this.utilsService.co(value);
          break;
        case 'bool':
          value = value === 'false' ? 0 : 1;
          buffer = this.utilsService.numberToBytes(value, 'uInt');
          break;
        case 'ip':
        case 'IP':
            
  
            //console.log(buffer);
            if(value != ''){
              var ipParts = value.split('.')
              var ipBytes = new Uint8Array(ipParts.length);
              ipBytes[3] = parseInt(ipParts[0],10)
              ipBytes[2] = parseInt(ipParts[1],10)
              ipBytes[1] = parseInt(ipParts[2],10)
              ipBytes[0] = parseInt(ipParts[3],10)
              buffer = ipBytes.buffer;           
            }else{
              var ipBytes = new Uint8Array(4);
              ipBytes[3] = parseInt('0',10)
              ipBytes[2] = parseInt('0',10)
              ipBytes[1] = parseInt('0',10)
              ipBytes[0] = parseInt('0',10)      
            }
            
            buffer = ipBytes.buffer;
            // for(var i=0;i<ipParts.length;i++){
            //   console.log('ipParts['+i+'] => '+ipParts[i]);
            //   //ipBytes[i] =  parseInt(this.utilsService.toHex(ipParts[i]));   
            //   ipBytes[i] =  parseInt(ipParts[i]);   
            //   console.log('ipBytes[i]');
            //   console.log(ipBytes[i]);
            // }
            // buffer = ipBytes.buffer;
            // console.log('ipBytes.buffer');
            // console.log(ipBytes.buffer);
            break;
        default:
          buffer = this.utilsService.numberToBytes(Number(value), type);
          break;
      }
  
    const gatt:BluetoothRemoteGATTServer = await this.ble.connectDevice(selectedDevice);
    console.log('[BLE::Info] GATT info %o', gatt);

    const primaryService = await this.ble.getPrimaryService(gatt, serviceUUID) as BluetoothRemoteGATTService;
    console.log('[BLE::Info] Primary Service info %o', primaryService);

    const characteristic = await this.ble.getCharacteristic(primaryService, characteristicUUID) as BluetoothRemoteGATTCharacteristic;
    console.log('[BLE::Info] Characteristic info %o', characteristic);

    console.log('[BLE::Info] Write value info %o', value);
    console.log(buffer);
     //const writeResponse  = await characteristic.writeValueWithResponse(buffer)
     //console.log(writeResponse);
     //return true;
      return characteristic.writeValue(buffer);
  }
}
