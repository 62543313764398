import { Element } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { BleService } from 'src/app/providers/ble.service';
import { StoreService } from '../../providers/store.service';
import { IDevice } from '../../interfaces/device.interfaces';
import { NavigationExtras, Router } from '@angular/router';


interface Status {
  key: string;
  value: string;
}
@Component({
  selector: 'app-wifi',
  templateUrl: './wifi.component.html',
  styleUrls: ['./wifi.component.scss'],
})
export class WifiComponent implements OnInit {
  ssid: string = '';
  password: string = '';
  status: string = '2';
  ipAddress: string = '';
  macAddress: string = '';
  wifiForm: FormGroup;
  deviceSelected:IDevice = {}
  writeErrors : String[] = []
  deviceType = "E3-MINI WIFI";

  statusList: string[] = ["Disconnected", "Connecting" ,"Connected", "Scanning", "Scan done" ];


  constructor(public bleService: BleService, private storeService:StoreService, private router: Router) {
    this.wifiForm = new FormGroup({
      ssid: new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
      ]),
      status:     new FormControl({value: '', disabled: true}, []),
      ipAddress:  new FormControl({value: '', disabled: true}, []),
      macAddress: new FormControl({value: '', disabled: true}, []),
    });
    //this.readValues();
  }

  ngOnInit(): void {
    console.log(this.bleService.selectedDevice);
    this.deviceSelected = this.storeService.searchForRegisteredDeviceByNamePattern(this.bleService.selectedDevice.name);
    this.readValues();
  }

  save() {
    console.log("click");
    this.writeValues();
  }

  readValues() {
    let sSID;
    let password;
    let IP;
    let MAC;
    console.log(this.deviceSelected);
  
    this.bleService.readValue(this.bleService.selectedDevice, this.deviceSelected.api.service, this.deviceSelected.api.connection.ssid, "string").then((result:any) => {
      sSID = result;  
      this.wifiForm.controls['ssid'].setValue(sSID)
    });
    this.bleService.readValue(this.bleService.selectedDevice, this.deviceSelected.api.service, this.deviceSelected.api.connection.password, "string").then((result:any) => {
      password = result;
      this.wifiForm.controls['password'].setValue(password)
    });

    this.bleService.readValue(this.bleService.selectedDevice, this.deviceSelected.api.service, this.deviceSelected.api.connectionStatus.connectionState).then((result:any) => {
      this.wifiForm.controls['status'].setValue(this.statusList[result])
    });
    console.log(this.deviceSelected);
    this.bleService.readValue(this.bleService.selectedDevice, this.deviceSelected.api.service, this.deviceSelected.api.connectionStatus.IP, "IP").then((result:any) => {
      IP = result;
      console.log("IP="+IP);     
      this.wifiForm.controls['ipAddress'].setValue(IP)
    });
    this.bleService.readValue(this.bleService.selectedDevice, this.deviceSelected.api.service, this.deviceSelected.api.connectionStatus.MAC, "MAC").then((result:any) => {
      MAC = result;
      this.wifiForm.controls['macAddress'].setValue(MAC)
    });

  }

  writeValues() {
    let deviceStatusEnabled;
      console.log("wifi component writeValues");
      this.bleService.writeValue(this.bleService.selectedDevice, this.deviceSelected.api.service, this.deviceSelected.api.connection.ssid, this.wifiForm.controls['ssid'].value, "string").then((result) => {
      console.log("SSID saved correctly!");
    }, (error)=>{
      this.writeErrors.push("SSID");
      console.log("SSID save error=> "+error);
    });
    this.bleService.writeValue(this.bleService.selectedDevice, this.deviceSelected.api.service, this.deviceSelected.api.connection.password,this.wifiForm.controls['password'].value, "string").then((result) => {
      console.log("password saved correctly!");
    }, (error)=>{
      this.writeErrors.push("Password");
      console.log("password save error=> "+error);
    });

    if(this.writeErrors.length == 0){
      let data =  {
          status: "ok",
          errors: null,
          device: this.deviceType
      }

      this.router.navigate(['status', {  status: "ok", errors: null, device: this.deviceType }])
    }else{
      // let navigationExtras: NavigationExtras = {
      //   state: {
      //     status: 'error',
      //     errors: this.writeErrors,
      //     deviceType: this.deviceType
      //   }
      // }
      // console.log(navigationExtras);
      // this.router.navigate(['/operation-status'], navigationExtras);
    }
    
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.wifiForm.controls[controlName].hasError(errorName);
  };

  isValid() {
    return this.wifiForm.valid;
  }
}
