import { OverlayContainer } from '@angular/cdk/overlay';
import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from './providers/store.service';
import { GlobalConstantsService } from './utils/constants.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Circutor-WebBluetooth';
  settedLanguage: string = "";
  activeLanguage: string = "";

  constructor(private router: Router,  
    private ionicStorageService: StoreService,
    private _translate: TranslateService) {

  }

  ngOnInit() {
    let self = this;
    let storedLang = localStorage.getItem(self.ionicStorageService.STORAGE_LANGUAGE_SELECTED.toString());
    if (!storedLang) {
      console.log(!storedLang);
      console.log(navigator.language.substr(0,2));
      this.settedLanguage = navigator.language.substr(0,2);
      this._translate.use(navigator.language.substr(0,2));
      this.activeLanguage = navigator.language.substr(0,2);
      localStorage.setItem(self.ionicStorageService.STORAGE_LANGUAGE_SELECTED.toString(), navigator.language.substr(0,2));
    }else{
      this.settedLanguage = storedLang.toString();
      this.activeLanguage = storedLang.toString();
      this._translate.use(storedLang);
      
    }
  }

  changeLanguage(languageSelected: string){
    this.settedLanguage = languageSelected;
    this._translate.use(languageSelected);
    this.activeLanguage =languageSelected;
    localStorage.setItem(this.ionicStorageService.STORAGE_LANGUAGE_SELECTED.toString(), languageSelected.substr(0,2));
    this._translate.use(languageSelected);
  }

}
