<form class="form" [formGroup]="wifiForm" novalidate><!-- (ngSubmit)="save()" -->
  <mat-form-field class="full-width">
    <mat-label>{{"SSID" | translate }}</mat-label>
    <input matInput formControlName="ssid" />
    <mat-error *ngIf="hasError('ssid', 'required')">{{"SSID is required" | translate }}</mat-error>
    <mat-error *ngIf="hasError('ssid', 'maxlenght')">{{"SSID too long" | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>Password</mat-label>
    <input
      formControlName="password"
      matInput
      placeholder="Password"
      type="password"
    />
    <mat-error *ngIf="hasError('password', 'required')">{{"Password is required" | translate }}</mat-error>
    <mat-error *ngIf="hasError('password', 'maxlenght')">{{"Password too long" | translate }}</mat-error>
  </mat-form-field>
  
  <mat-form-field class="full-width">
    <mat-label>{{"Status" | translate }}</mat-label>
    <input formControlName="status" matInput placeholder="status"  />
  </mat-form-field>


  <mat-form-field class="full-width">
    <mat-label>{{"IP address" | translate}}</mat-label>
    <input formControlName="ipAddress" matInput placeholder="IP address" />
    <mat-error *ngIf="hasError('ipAddress', 'required')"
      >IP address is required</mat-error
    >
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>{{"MAC address" | translate }}</mat-label>
    <input formControlName="macAddress" matInput placeholder="MAC address"  />
  </mat-form-field>
  <button
    class="full-width"
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!wifiForm.valid"
  > 
  {{"Save data" | translate }}
  </button>
</form>
<!-- <div>reactiveForm.valid= {{ wifiForm.valid }}</div>
<div>reactiveForm.invalid = {{ wifiForm.invalid }}</div>
<div>reactiveForm.status = {{ wifiForm.status }}</div>
<div>reactiveForm.disabled = {{ wifiForm.disabled }}</div> -->
