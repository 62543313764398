
export const BLE_API = {
  // STM-C-RS485
  'STM_MODBUS': {
    service: '93A9CAE0-D07A-11E8-BDCB-0800275AA6E5',
    measurements: {
      integrationTime: '7C112B90-DB64-11E8-9F8B-F2801F1B9FD1'
    },
    communicationModbus: {
      peripheralNumber: 'DA9C09C4-D929-11E8-9F8B-F2801F1B9FD1',
      baudRate: '5CD0AAE0-DB5E-11E8-9F8B-F2801F1B9FD1'
    },
    alarms: {
      connectedChannelsNumber: 'C0DBF396-DB68-11E8-9F8B-F2801F1B9FD1',
      currentDeviationPercentage: 'EE45B432-DB6A-11E8-9F8B-F2801F1B9FD1',
      currentDeviationTime: 'DBDE934E-E35E-11E8-9F32-F2801F1B9FD1'
    },
    inputs: {
      temperatureProbe: 'AA2E2C3E-DB79-11E8-9F8B-F2801F1B9FD1'
    }
  },

  // STM-C-LoRa
  'STM_LORA': {
    service: '93A9CAE0-D07A-11E8-BDCB-0800275AA6E5',
    serviceLora: 'BE7E1E82-D846-11E8-9F8B-F2801F1B9FD1',
    measurements: {
      integrationTime: '7C112B90-DB64-11E8-9F8B-F2801F1B9FD1'
    },
    communicationLora_RS485: {
      peripheralNumber: 'DA9C09C4-D929-11E8-9F8B-F2801F1B9FD1',
    },
    communicationLora_wireless: {
      uplinkMode: '8F09768E-DB97-11E8-9F8B-F2801F1B9FD1',
      downlinkMode: '5A8CE592-DB99-11E8-9F8B-F2801F1B9FD1',
      uplinkModeFreq: '3284A688-DB9A-11E8-9F8B-F2801F1B9FD1',
      downlinkModeFreq: '16FE7014-DB9B-11E8-9F8B-F2801F1B9FD1',
      transmissionPower: 'B260FAE6-DC1C-11E8-9F8B-F2801F1B9FD1',
    },
    alarms: {
      connectedChannelsNumber: 'C0DBF396-DB68-11E8-9F8B-F2801F1B9FD1',
      currentDeviationPercentage: 'EE45B432-DB6A-11E8-9F8B-F2801F1B9FD1',
      currentDeviationTime: 'DBDE934E-E35E-11E8-9F32-F2801F1B9FD1'
    },
    inputs: {
      temperatureProbe: 'AA2E2C3E-DB79-11E8-9F8B-F2801F1B9FD1'
    }
  },
  // E3mini - WIFI
  'WIFI': {
    service: '1aeaffac-ec30-11e9-81b4-2a2ae2dbcce4',
    deviceStatus: {
      enabled: '8ac9bd5e-ec0d-11e9-81b4-2a2ae2dbcce4'
    },
    connection: {
      ssid: '044ef4d0-ec29-11e9-81b4-2a2ae2dbcce4',
      password: '3b53940c-ec2b-11e9-81b4-2a2ae2dbcce4',
    },
    connectionStatus: {
      connectionState: '956e665a-ec22-11e9-81b4-2a2ae2dbcce4',
     // IP:  '956e665a-ec22-11e9-81b4-2a2ae2dbcce4',
      IP:  'e001758e-ec1f-11e9-81b4-2a2ae2dbcce4',
      MAC: '7589046e-ec20-11e9-81b4-2a2ae2dbcce4'
    }
  },
  // Ethernet-Ethernet
  'ETHERNET': {
    service: '7548B7C6-EC2D-11E9-81B4-2A2AE2DBCCE4',
    DHCP: {
      enabled: '06CB711C-EBF6-11E9-81B4-2A2AE2DBCCE4'
    },
    network: {
      IP      : 'AF8952D4-EBFF-11E9-81B4-2A2AE2DBCCE4',
      netMask : '8DDE463C-EC02-11E9-81B4-2A2AE2DBCCE4',
      gateway : '3CDEDC6E-EC08-11E9-81B4-2A2AE2DBCCE4',
      MAC     : 'F1C1F716-EC0C-11E9-81B4-2A2AE2DBCCE4'

    }
  }
};
